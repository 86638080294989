export enum Decision {
    approved = "approved",
    notApproved = "not_approved",
    disputable = "disputable",
    empty = ""
};


export const ToDecision = (input: string): Decision  => {
    switch (input) {
        case Decision.approved:
            return Decision.approved
        case Decision.notApproved:
            return Decision.notApproved
        case Decision.disputable:
            return Decision.disputable
        case Decision.empty:
            return Decision.empty
        default:
            return Decision.empty
    }
}

export const DecisionDescription = (input: string): string => {
    switch (input) {
        case Decision.approved:
            return "Одобрен"
        case Decision.notApproved:
            return "Не одобрен"
        case Decision.disputable:
            return "Спорный"
        default:
            return ""
    }
}