/* tslint:disable */
/* eslint-disable */
/**
 * hr-report backend
 * hr-report backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AmofileAmoFileListResponse
 */
export interface AmofileAmoFileListResponse {
    /**
     * 
     * @type {number}
     * @memberof AmofileAmoFileListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<AmofileAmoFileResponse>}
     * @memberof AmofileAmoFileListResponse
     */
    'list'?: Array<AmofileAmoFileResponse>;
}
/**
 * 
 * @export
 * @interface AmofileAmoFileResponse
 */
export interface AmofileAmoFileResponse {
    /**
     * 
     * @type {string}
     * @memberof AmofileAmoFileResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AmofileAmoFileResponse
     */
    'file': string;
    /**
     * 
     * @type {number}
     * @memberof AmofileAmoFileResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AmofileAmoFileResponse
     */
    'leadId': number;
    /**
     * 
     * @type {string}
     * @memberof AmofileAmoFileResponse
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AmofileAmoFileResponse
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface AmofileMassDeleteRequest
 */
export interface AmofileMassDeleteRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AmofileMassDeleteRequest
     */
    'ids': Array<number>;
}
/**
 * 
 * @export
 * @interface AmofileMassDeleteResponse
 */
export interface AmofileMassDeleteResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof AmofileMassDeleteResponse
     */
    'deleted_ids': Array<number>;
}
/**
 * 
 * @export
 * @interface AmofileModelsResponse
 */
export interface AmofileModelsResponse {
    /**
     * 
     * @type {string}
     * @memberof AmofileModelsResponse
     */
    'models'?: string;
}
/**
 * 
 * @export
 * @interface AuthSignInRequest
 */
export interface AuthSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface CauseCauseResponse
 */
export interface CauseCauseResponse {
    /**
     * 
     * @type {number}
     * @memberof CauseCauseResponse
     */
    'id'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CauseCauseResponse
     */
    'items'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CauseCauseResponse
     */
    'packet_ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CauseCreateCauseRequest
 */
export interface CauseCreateCauseRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CauseCreateCauseRequest
     */
    'items'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CauseCreateCauseRequest
     */
    'packet_ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CauseUpdateCauseRequest
 */
export interface CauseUpdateCauseRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CauseUpdateCauseRequest
     */
    'items'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CauseUpdateCauseRequest
     */
    'packet_ids'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CityCityListResponse
 */
export interface CityCityListResponse {
    /**
     * 
     * @type {Array<ModerationInternalDeliveryCityCity>}
     * @memberof CityCityListResponse
     */
    'list': Array<ModerationInternalDeliveryCityCity>;
}
/**
 * 
 * @export
 * @interface ClientClientListResponse
 */
export interface ClientClientListResponse {
    /**
     * 
     * @type {Array<ModerationInternalDeliveryClientClient>}
     * @memberof ClientClientListResponse
     */
    'list': Array<ModerationInternalDeliveryClientClient>;
}
/**
 * 
 * @export
 * @interface DeliveryErrorResponse
 */
export interface DeliveryErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof DeliveryErrorResponse
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface LeadClientCauseEdit
 */
export interface LeadClientCauseEdit {
    /**
     * 
     * @type {string}
     * @memberof LeadClientCauseEdit
     */
    'cause'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LeadDecision = {
    Approved: 'approved',
    NotApproved: 'not_approved',
    Disputable: 'disputable',
    EmptyD: ''
} as const;

export type LeadDecision = typeof LeadDecision[keyof typeof LeadDecision];


/**
 * 
 * @export
 * @interface LeadFileResponse
 */
export interface LeadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof LeadFileResponse
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface LeadItemDescription
 */
export interface LeadItemDescription {
    /**
     * 
     * @type {string}
     * @memberof LeadItemDescription
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadItemDescription
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface LeadLeadListResponse
 */
export interface LeadLeadListResponse {
    /**
     * 
     * @type {number}
     * @memberof LeadLeadListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<LeadLeadResponse>}
     * @memberof LeadLeadListResponse
     */
    'list'?: Array<LeadLeadResponse>;
}
/**
 * 
 * @export
 * @interface LeadLeadResponse
 */
export interface LeadLeadResponse {
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'actionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'cause'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadLeadResponse
     */
    'cityId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'cityName'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadLeadResponse
     */
    'clientId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LeadLeadResponse
     */
    'clientLeadsGrowth'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'clientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'clientReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'createdAt'?: string;
    /**
     * 
     * @type {LeadDecision}
     * @memberof LeadLeadResponse
     */
    'decision'?: LeadDecision;
    /**
     * 
     * @type {number}
     * @memberof LeadLeadResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadLeadResponse
     */
    'managerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'moderateAddDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'moderatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadLeadResponse
     */
    'moderatorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'packet'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadLeadResponse
     */
    'price'?: number;
    /**
     * 
     * @type {LeadProcessingToAmoStatus}
     * @memberof LeadLeadResponse
     */
    'processedToAmo'?: LeadProcessingToAmoStatus;
    /**
     * 
     * @type {LeadStatus}
     * @memberof LeadLeadResponse
     */
    'status'?: LeadStatus;
    /**
     * 
     * @type {string}
     * @memberof LeadLeadResponse
     */
    'transferDate'?: string;
}
/**
 * 
 * @export
 * @interface LeadModeratorDecision
 */
export interface LeadModeratorDecision {
    /**
     * 
     * @type {string}
     * @memberof LeadModeratorDecision
     */
    'cause'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadModeratorDecision
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadModeratorDecision
     */
    'decision'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LeadProcessingToAmoStatus = {
    NotProcessed: 'not_processed',
    Processed: 'processed',
    ErrorProcessing: 'error_processing'
} as const;

export type LeadProcessingToAmoStatus = typeof LeadProcessingToAmoStatus[keyof typeof LeadProcessingToAmoStatus];


/**
 * 
 * @export
 * @interface LeadSetModeratorRequest
 */
export interface LeadSetModeratorRequest {
    /**
     * 
     * @type {number}
     * @memberof LeadSetModeratorRequest
     */
    'moderator_id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LeadStatus = {
    Moderating: 'moderating',
    Moderated: 'moderated',
    ExchangeToZero: 'exchange_to_zero',
    Rejected: 'rejected',
    Annul: 'annul',
    Empty: ''
} as const;

export type LeadStatus = typeof LeadStatus[keyof typeof LeadStatus];


/**
 * 
 * @export
 * @interface ModerationInternalDeliveryAuthSignInResponse
 */
export interface ModerationInternalDeliveryAuthSignInResponse {
    /**
     * 
     * @type {string}
     * @memberof ModerationInternalDeliveryAuthSignInResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModerationInternalDeliveryAuthSignInResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface ModerationInternalDeliveryCityCity
 */
export interface ModerationInternalDeliveryCityCity {
    /**
     * 
     * @type {number}
     * @memberof ModerationInternalDeliveryCityCity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ModerationInternalDeliveryCityCity
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ModerationInternalDeliveryClientClient
 */
export interface ModerationInternalDeliveryClientClient {
    /**
     * 
     * @type {number}
     * @memberof ModerationInternalDeliveryClientClient
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ModerationInternalDeliveryClientClient
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ModerationInternalDeliveryPacketPacket
 */
export interface ModerationInternalDeliveryPacketPacket {
    /**
     * 
     * @type {number}
     * @memberof ModerationInternalDeliveryPacketPacket
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ModerationInternalDeliveryPacketPacket
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PacketPacketListResponse
 */
export interface PacketPacketListResponse {
    /**
     * 
     * @type {Array<ModerationInternalDeliveryPacketPacket>}
     * @memberof PacketPacketListResponse
     */
    'list': Array<ModerationInternalDeliveryPacketPacket>;
}
/**
 * 
 * @export
 * @interface UserChangePasswordRequest
 */
export interface UserChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UserChangePasswordRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface UserCreateUserRequest
 */
export interface UserCreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserCreateUserRequest
     */
    'deputy'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateUserRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserCreateUserRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'pass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateUserRequest
     */
    'surname'?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateUserRequest
 */
export interface UserUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'created_at'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserUpdateUserRequest
     */
    'deputy'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateUserRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'pass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateUserRequest
     */
    'surname'?: string;
}
/**
 * 
 * @export
 * @interface UserUserResponse
 */
export interface UserUserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserUserResponse
     */
    'deputy'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof UserUserResponse
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserUserResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'pass'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'role'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUserResponse
     */
    'surname'?: string;
}

/**
 * AmofileApi - axios parameter creator
 * @export
 */
export const AmofileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * создание лида и файлов для амо.
         * @summary создание лида и файлов для амо.
         * @param {string} phone Body
         * @param {number} carId Body
         * @param {string} utmSource Body
         * @param {string} tag Body
         * @param {string} cityName Body
         * @param {string} clientName Body
         * @param {string} regionName Body
         * @param {boolean} isNew Body
         * @param {File} file Background of kviz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileCarPost: async (phone: string, carId: number, utmSource: string, tag: string, cityName: string, clientName: string, regionName: string, isNew: boolean, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('apiAmofileCarPost', 'phone', phone)
            // verify required parameter 'carId' is not null or undefined
            assertParamExists('apiAmofileCarPost', 'carId', carId)
            // verify required parameter 'utmSource' is not null or undefined
            assertParamExists('apiAmofileCarPost', 'utmSource', utmSource)
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('apiAmofileCarPost', 'tag', tag)
            // verify required parameter 'cityName' is not null or undefined
            assertParamExists('apiAmofileCarPost', 'cityName', cityName)
            // verify required parameter 'clientName' is not null or undefined
            assertParamExists('apiAmofileCarPost', 'clientName', clientName)
            // verify required parameter 'regionName' is not null or undefined
            assertParamExists('apiAmofileCarPost', 'regionName', regionName)
            // verify required parameter 'isNew' is not null or undefined
            assertParamExists('apiAmofileCarPost', 'isNew', isNew)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiAmofileCarPost', 'file', file)
            const localVarPath = `/api/amofile/car`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (phone !== undefined) { 
                localVarFormParams.append('phone', phone as any);
            }
    
            if (carId !== undefined) { 
                localVarFormParams.append('car_id', carId as any);
            }
    
            if (utmSource !== undefined) { 
                localVarFormParams.append('utm_source', utmSource as any);
            }
    
            if (tag !== undefined) { 
                localVarFormParams.append('tag', tag as any);
            }
    
            if (cityName !== undefined) { 
                localVarFormParams.append('city_name', cityName as any);
            }
    
            if (clientName !== undefined) { 
                localVarFormParams.append('client_name', clientName as any);
            }
    
            if (regionName !== undefined) { 
                localVarFormParams.append('region_name', regionName as any);
            }
    
            if (isNew !== undefined) { 
                localVarFormParams.append('is_new', isNew as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание лида и файлов для амо из excel файла.
         * @summary создание лида и файлов для амо из excel файла.
         * @param {File} file Background of kviz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileCarXlsxPost: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiAmofileCarXlsxPost', 'file', file)
            const localVarPath = `/api/amofile/car/xlsx`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список файлов амо.
         * @summary получить список файлов амо.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileGet: async (offset?: number, limit?: number, sorting?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/amofile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить afqk
         * @summary удалить afqk
         * @param {string} id Идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAmofileIdDelete', 'id', id)
            const localVarPath = `/api/amofile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список файлов амо.
         * @summary получить список файлов амо.
         * @param {string} id Идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAmofileIdGet', 'id', id)
            const localVarPath = `/api/amofile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание файла для амо.
         * @summary создание файла для амо.
         * @param {string} id Идентификатор лида
         * @param {Array<File>} file Файл для амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileIdPost: async (id: string, file: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAmofileIdPost', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiAmofileIdPost', 'file', file)
            const localVarPath = `/api/amofile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * массовое удаление файлов
         * @summary массовое удаление файлов
         * @param {AmofileMassDeleteRequest} data Данные о решение модератора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileMassDelete: async (data: AmofileMassDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiAmofileMassDelete', 'data', data)
            const localVarPath = `/api/amofile/mass`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список моделей авто.
         * @summary получить список моделей авто.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileModelsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/amofile/models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AmofileApi - functional programming interface
 * @export
 */
export const AmofileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AmofileApiAxiosParamCreator(configuration)
    return {
        /**
         * создание лида и файлов для амо.
         * @summary создание лида и файлов для амо.
         * @param {string} phone Body
         * @param {number} carId Body
         * @param {string} utmSource Body
         * @param {string} tag Body
         * @param {string} cityName Body
         * @param {string} clientName Body
         * @param {string} regionName Body
         * @param {boolean} isNew Body
         * @param {File} file Background of kviz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAmofileCarPost(phone: string, carId: number, utmSource: string, tag: string, cityName: string, clientName: string, regionName: string, isNew: boolean, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmofileAmoFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAmofileCarPost(phone, carId, utmSource, tag, cityName, clientName, regionName, isNew, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание лида и файлов для амо из excel файла.
         * @summary создание лида и файлов для амо из excel файла.
         * @param {File} file Background of kviz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAmofileCarXlsxPost(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmofileAmoFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAmofileCarXlsxPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список файлов амо.
         * @summary получить список файлов амо.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAmofileGet(offset?: number, limit?: number, sorting?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmofileAmoFileListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAmofileGet(offset, limit, sorting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить afqk
         * @summary удалить afqk
         * @param {string} id Идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAmofileIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAmofileIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список файлов амо.
         * @summary получить список файлов амо.
         * @param {string} id Идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAmofileIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmofileAmoFileListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAmofileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание файла для амо.
         * @summary создание файла для амо.
         * @param {string} id Идентификатор лида
         * @param {Array<File>} file Файл для амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAmofileIdPost(id: string, file: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AmofileAmoFileResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAmofileIdPost(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * массовое удаление файлов
         * @summary массовое удаление файлов
         * @param {AmofileMassDeleteRequest} data Данные о решение модератора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAmofileMassDelete(data: AmofileMassDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmofileMassDeleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAmofileMassDelete(data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список моделей авто.
         * @summary получить список моделей авто.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAmofileModelsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmofileModelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAmofileModelsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AmofileApi - factory interface
 * @export
 */
export const AmofileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AmofileApiFp(configuration)
    return {
        /**
         * создание лида и файлов для амо.
         * @summary создание лида и файлов для амо.
         * @param {string} phone Body
         * @param {number} carId Body
         * @param {string} utmSource Body
         * @param {string} tag Body
         * @param {string} cityName Body
         * @param {string} clientName Body
         * @param {string} regionName Body
         * @param {boolean} isNew Body
         * @param {File} file Background of kviz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileCarPost(phone: string, carId: number, utmSource: string, tag: string, cityName: string, clientName: string, regionName: string, isNew: boolean, file: File, options?: any): AxiosPromise<AmofileAmoFileResponse> {
            return localVarFp.apiAmofileCarPost(phone, carId, utmSource, tag, cityName, clientName, regionName, isNew, file, options).then((request) => request(axios, basePath));
        },
        /**
         * создание лида и файлов для амо из excel файла.
         * @summary создание лида и файлов для амо из excel файла.
         * @param {File} file Background of kviz
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileCarXlsxPost(file: File, options?: any): AxiosPromise<AmofileAmoFileResponse> {
            return localVarFp.apiAmofileCarXlsxPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список файлов амо.
         * @summary получить список файлов амо.
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileGet(offset?: number, limit?: number, sorting?: string, options?: any): AxiosPromise<AmofileAmoFileListResponse> {
            return localVarFp.apiAmofileGet(offset, limit, sorting, options).then((request) => request(axios, basePath));
        },
        /**
         * удалить afqk
         * @summary удалить afqk
         * @param {string} id Идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAmofileIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список файлов амо.
         * @summary получить список файлов амо.
         * @param {string} id Идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileIdGet(id: string, options?: any): AxiosPromise<AmofileAmoFileListResponse> {
            return localVarFp.apiAmofileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * создание файла для амо.
         * @summary создание файла для амо.
         * @param {string} id Идентификатор лида
         * @param {Array<File>} file Файл для амо
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileIdPost(id: string, file: Array<File>, options?: any): AxiosPromise<Array<AmofileAmoFileResponse>> {
            return localVarFp.apiAmofileIdPost(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * массовое удаление файлов
         * @summary массовое удаление файлов
         * @param {AmofileMassDeleteRequest} data Данные о решение модератора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileMassDelete(data: AmofileMassDeleteRequest, options?: any): AxiosPromise<AmofileMassDeleteResponse> {
            return localVarFp.apiAmofileMassDelete(data, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список моделей авто.
         * @summary получить список моделей авто.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAmofileModelsGet(options?: any): AxiosPromise<AmofileModelsResponse> {
            return localVarFp.apiAmofileModelsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AmofileApi - object-oriented interface
 * @export
 * @class AmofileApi
 * @extends {BaseAPI}
 */
export class AmofileApi extends BaseAPI {
    /**
     * создание лида и файлов для амо.
     * @summary создание лида и файлов для амо.
     * @param {string} phone Body
     * @param {number} carId Body
     * @param {string} utmSource Body
     * @param {string} tag Body
     * @param {string} cityName Body
     * @param {string} clientName Body
     * @param {string} regionName Body
     * @param {boolean} isNew Body
     * @param {File} file Background of kviz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmofileApi
     */
    public apiAmofileCarPost(phone: string, carId: number, utmSource: string, tag: string, cityName: string, clientName: string, regionName: string, isNew: boolean, file: File, options?: AxiosRequestConfig) {
        return AmofileApiFp(this.configuration).apiAmofileCarPost(phone, carId, utmSource, tag, cityName, clientName, regionName, isNew, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание лида и файлов для амо из excel файла.
     * @summary создание лида и файлов для амо из excel файла.
     * @param {File} file Background of kviz
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmofileApi
     */
    public apiAmofileCarXlsxPost(file: File, options?: AxiosRequestConfig) {
        return AmofileApiFp(this.configuration).apiAmofileCarXlsxPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список файлов амо.
     * @summary получить список файлов амо.
     * @param {number} [offset] Начальный индекс
     * @param {number} [limit] Лимит
     * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmofileApi
     */
    public apiAmofileGet(offset?: number, limit?: number, sorting?: string, options?: AxiosRequestConfig) {
        return AmofileApiFp(this.configuration).apiAmofileGet(offset, limit, sorting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить afqk
     * @summary удалить afqk
     * @param {string} id Идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmofileApi
     */
    public apiAmofileIdDelete(id: string, options?: AxiosRequestConfig) {
        return AmofileApiFp(this.configuration).apiAmofileIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список файлов амо.
     * @summary получить список файлов амо.
     * @param {string} id Идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmofileApi
     */
    public apiAmofileIdGet(id: string, options?: AxiosRequestConfig) {
        return AmofileApiFp(this.configuration).apiAmofileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание файла для амо.
     * @summary создание файла для амо.
     * @param {string} id Идентификатор лида
     * @param {Array<File>} file Файл для амо
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmofileApi
     */
    public apiAmofileIdPost(id: string, file: Array<File>, options?: AxiosRequestConfig) {
        return AmofileApiFp(this.configuration).apiAmofileIdPost(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * массовое удаление файлов
     * @summary массовое удаление файлов
     * @param {AmofileMassDeleteRequest} data Данные о решение модератора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmofileApi
     */
    public apiAmofileMassDelete(data: AmofileMassDeleteRequest, options?: AxiosRequestConfig) {
        return AmofileApiFp(this.configuration).apiAmofileMassDelete(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список моделей авто.
     * @summary получить список моделей авто.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AmofileApi
     */
    public apiAmofileModelsGet(options?: AxiosRequestConfig) {
        return AmofileApiFp(this.configuration).apiAmofileModelsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost: async (user: AuthSignInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiAuthSignInPost', 'user', user)
            const localVarPath = `/api/auth/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModerationInternalDeliveryAuthSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthSignInPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost(user: AuthSignInRequest, options?: any): AxiosPromise<ModerationInternalDeliveryAuthSignInResponse> {
            return localVarFp.apiAuthSignInPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * авторизация пользователя.
     * @summary авторизация пользователя.
     * @param {AuthSignInRequest} user Данные для аутентификации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthSignInPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CausesApi - axios parameter creator
 * @export
 */
export const CausesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех причин.
         * @summary получить список всех причин.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCauseGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cause/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить причины
         * @summary удалить причины
         * @param {string} id Идентификатор причины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCauseIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCauseIdDelete', 'id', id)
            const localVarPath = `/api/cause/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить причины по id.
         * @summary получить причины по id.
         * @param {string} id Идентификатор причины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCauseIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCauseIdGet', 'id', id)
            const localVarPath = `/api/cause/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление списка причин.
         * @summary обновление списка причин.
         * @param {string} id Идентификатор пользователя
         * @param {CauseUpdateCauseRequest} cause Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCauseIdPut: async (id: string, cause: CauseUpdateCauseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCauseIdPut', 'id', id)
            // verify required parameter 'cause' is not null or undefined
            assertParamExists('apiCauseIdPut', 'cause', cause)
            const localVarPath = `/api/cause/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cause, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить причины по id  лида.
         * @summary получить причины по id лида.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCauseLeadIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCauseLeadIdGet', 'id', id)
            const localVarPath = `/api/cause/lead/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить причины для пакета.
         * @summary получить причины для пакета.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCausePacketKeyIdPost: async (id: string, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCausePacketKeyIdPost', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiCausePacketKeyIdPost', 'key', key)
            const localVarPath = `/api/cause/packet/{key}/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание списка причин.
         * @summary создание списка причин.
         * @param {CauseCreateCauseRequest} cause Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCausePost: async (cause: CauseCreateCauseRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cause' is not null or undefined
            assertParamExists('apiCausePost', 'cause', cause)
            const localVarPath = `/api/cause/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cause, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CausesApi - functional programming interface
 * @export
 */
export const CausesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CausesApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех причин.
         * @summary получить список всех причин.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCauseGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CauseCauseResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCauseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить причины
         * @summary удалить причины
         * @param {string} id Идентификатор причины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCauseIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CauseCauseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCauseIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить причины по id.
         * @summary получить причины по id.
         * @param {string} id Идентификатор причины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCauseIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CauseCauseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCauseIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление списка причин.
         * @summary обновление списка причин.
         * @param {string} id Идентификатор пользователя
         * @param {CauseUpdateCauseRequest} cause Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCauseIdPut(id: string, cause: CauseUpdateCauseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CauseCauseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCauseIdPut(id, cause, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить причины по id  лида.
         * @summary получить причины по id лида.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCauseLeadIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CauseCauseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCauseLeadIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить причины для пакета.
         * @summary получить причины для пакета.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCausePacketKeyIdPost(id: string, key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCausePacketKeyIdPost(id, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание списка причин.
         * @summary создание списка причин.
         * @param {CauseCreateCauseRequest} cause Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCausePost(cause: CauseCreateCauseRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CauseCauseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCausePost(cause, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CausesApi - factory interface
 * @export
 */
export const CausesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CausesApiFp(configuration)
    return {
        /**
         * получить список всех причин.
         * @summary получить список всех причин.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCauseGet(options?: any): AxiosPromise<Array<CauseCauseResponse>> {
            return localVarFp.apiCauseGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить причины
         * @summary удалить причины
         * @param {string} id Идентификатор причины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCauseIdDelete(id: string, options?: any): AxiosPromise<CauseCauseResponse> {
            return localVarFp.apiCauseIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить причины по id.
         * @summary получить причины по id.
         * @param {string} id Идентификатор причины
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCauseIdGet(id: string, options?: any): AxiosPromise<CauseCauseResponse> {
            return localVarFp.apiCauseIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление списка причин.
         * @summary обновление списка причин.
         * @param {string} id Идентификатор пользователя
         * @param {CauseUpdateCauseRequest} cause Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCauseIdPut(id: string, cause: CauseUpdateCauseRequest, options?: any): AxiosPromise<CauseCauseResponse> {
            return localVarFp.apiCauseIdPut(id, cause, options).then((request) => request(axios, basePath));
        },
        /**
         * получить причины по id  лида.
         * @summary получить причины по id лида.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCauseLeadIdGet(id: string, options?: any): AxiosPromise<CauseCauseResponse> {
            return localVarFp.apiCauseLeadIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить причины для пакета.
         * @summary получить причины для пакета.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCausePacketKeyIdPost(id: string, key: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiCausePacketKeyIdPost(id, key, options).then((request) => request(axios, basePath));
        },
        /**
         * создание списка причин.
         * @summary создание списка причин.
         * @param {CauseCreateCauseRequest} cause Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCausePost(cause: CauseCreateCauseRequest, options?: any): AxiosPromise<CauseCauseResponse> {
            return localVarFp.apiCausePost(cause, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CausesApi - object-oriented interface
 * @export
 * @class CausesApi
 * @extends {BaseAPI}
 */
export class CausesApi extends BaseAPI {
    /**
     * получить список всех причин.
     * @summary получить список всех причин.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CausesApi
     */
    public apiCauseGet(options?: AxiosRequestConfig) {
        return CausesApiFp(this.configuration).apiCauseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить причины
     * @summary удалить причины
     * @param {string} id Идентификатор причины
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CausesApi
     */
    public apiCauseIdDelete(id: string, options?: AxiosRequestConfig) {
        return CausesApiFp(this.configuration).apiCauseIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить причины по id.
     * @summary получить причины по id.
     * @param {string} id Идентификатор причины
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CausesApi
     */
    public apiCauseIdGet(id: string, options?: AxiosRequestConfig) {
        return CausesApiFp(this.configuration).apiCauseIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление списка причин.
     * @summary обновление списка причин.
     * @param {string} id Идентификатор пользователя
     * @param {CauseUpdateCauseRequest} cause Данные для обновления пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CausesApi
     */
    public apiCauseIdPut(id: string, cause: CauseUpdateCauseRequest, options?: AxiosRequestConfig) {
        return CausesApiFp(this.configuration).apiCauseIdPut(id, cause, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить причины по id  лида.
     * @summary получить причины по id лида.
     * @param {string} id Идентификатор лида
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CausesApi
     */
    public apiCauseLeadIdGet(id: string, options?: AxiosRequestConfig) {
        return CausesApiFp(this.configuration).apiCauseLeadIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить причины для пакета.
     * @summary получить причины для пакета.
     * @param {string} id Идентификатор лида
     * @param {string} key Api ключ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CausesApi
     */
    public apiCausePacketKeyIdPost(id: string, key: string, options?: AxiosRequestConfig) {
        return CausesApiFp(this.configuration).apiCausePacketKeyIdPost(id, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание списка причин.
     * @summary создание списка причин.
     * @param {CauseCreateCauseRequest} cause Данные пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CausesApi
     */
    public apiCausePost(cause: CauseCreateCauseRequest, options?: AxiosRequestConfig) {
        return CausesApiFp(this.configuration).apiCausePost(cause, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CityApi - axios parameter creator
 * @export
 */
export const CityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Возвращает список  городов портала.
         * @summary Возвращает список  городов портала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCityGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/city`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CityApi - functional programming interface
 * @export
 */
export const CityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CityApiAxiosParamCreator(configuration)
    return {
        /**
         * Возвращает список  городов портала.
         * @summary Возвращает список  городов портала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCityGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityCityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCityGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CityApi - factory interface
 * @export
 */
export const CityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CityApiFp(configuration)
    return {
        /**
         * Возвращает список  городов портала.
         * @summary Возвращает список  городов портала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCityGet(options?: any): AxiosPromise<CityCityListResponse> {
            return localVarFp.apiCityGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CityApi - object-oriented interface
 * @export
 * @class CityApi
 * @extends {BaseAPI}
 */
export class CityApi extends BaseAPI {
    /**
     * Возвращает список  городов портала.
     * @summary Возвращает список  городов портала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public apiCityGet(options?: AxiosRequestConfig) {
        return CityApiFp(this.configuration).apiCityGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Возвращает список клиентов.
         * @summary Возвращает список клиентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * Возвращает список клиентов.
         * @summary Возвращает список клиентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientClientListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * Возвращает список клиентов.
         * @summary Возвращает список клиентов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGet(options?: any): AxiosPromise<ClientClientListResponse> {
            return localVarFp.apiClientGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * Возвращает список клиентов.
     * @summary Возвращает список клиентов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientGet(options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Редирект на страницу авторизации приложения в амо.
         * @summary Редирект на страницу авторизации приложения в амо.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amocrmAuthGet: async (id: string, clientId: string, code: string, referer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('amocrmAuthGet', 'id', id)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('amocrmAuthGet', 'clientId', clientId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('amocrmAuthGet', 'code', code)
            // verify required parameter 'referer' is not null or undefined
            assertParamExists('amocrmAuthGet', 'referer', referer)
            const localVarPath = `/amocrm/auth`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (referer !== undefined) {
                localVarQueryParameter['referer'] = referer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @summary Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amocrmCodeGet: async (id: string, clientId: string, code: string, referer: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('amocrmCodeGet', 'id', id)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('amocrmCodeGet', 'clientId', clientId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('amocrmCodeGet', 'code', code)
            // verify required parameter 'referer' is not null or undefined
            assertParamExists('amocrmCodeGet', 'referer', referer)
            const localVarPath = `/amocrm/code`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (referer !== undefined) {
                localVarQueryParameter['referer'] = referer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Редирект на страницу авторизации приложения в амо.
         * @summary Редирект на страницу авторизации приложения в амо.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async amocrmAuthGet(id: string, clientId: string, code: string, referer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.amocrmAuthGet(id, clientId, code, referer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @summary Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async amocrmCodeGet(id: string, clientId: string, code: string, referer: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.amocrmCodeGet(id, clientId, code, referer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationApiFp(configuration)
    return {
        /**
         * Редирект на страницу авторизации приложения в амо.
         * @summary Редирект на страницу авторизации приложения в амо.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amocrmAuthGet(id: string, clientId: string, code: string, referer: string, options?: any): AxiosPromise<void> {
            return localVarFp.amocrmAuthGet(id, clientId, code, referer, options).then((request) => request(axios, basePath));
        },
        /**
         * Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @summary Получаем код авторизации из oauth и обновляем или создаем интеграцию.
         * @param {string} id Project ID
         * @param {string} clientId 
         * @param {string} code 
         * @param {string} referer 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        amocrmCodeGet(id: string, clientId: string, code: string, referer: string, options?: any): AxiosPromise<void> {
            return localVarFp.amocrmCodeGet(id, clientId, code, referer, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI {
    /**
     * Редирект на страницу авторизации приложения в амо.
     * @summary Редирект на страницу авторизации приложения в амо.
     * @param {string} id Project ID
     * @param {string} clientId 
     * @param {string} code 
     * @param {string} referer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public amocrmAuthGet(id: string, clientId: string, code: string, referer: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).amocrmAuthGet(id, clientId, code, referer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получаем код авторизации из oauth и обновляем или создаем интеграцию.
     * @summary Получаем код авторизации из oauth и обновляем или создаем интеграцию.
     * @param {string} id Project ID
     * @param {string} clientId 
     * @param {string} code 
     * @param {string} referer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public amocrmCodeGet(id: string, clientId: string, code: string, referer: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).amocrmCodeGet(id, clientId, code, referer, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadsApi - axios parameter creator
 * @export
 */
export const LeadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * анулировать лид.
         * @summary анулировать лид.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadAnnulIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadAnnulIdPut', 'id', id)
            const localVarPath = `/api/lead/annul/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * редактирование причины клиента.
         * @summary редактирование причины клиента.
         * @param {string} id Идентификатор лида
         * @param {LeadClientCauseEdit} data Данные о причине клиента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadClientCauseIdPut: async (id: string, data: LeadClientCauseEdit, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadClientCauseIdPut', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiLeadClientCauseIdPut', 'data', data)
            const localVarPath = `/api/lead/client-cause/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить отфильтрованный список лидов  в csv.
         * @summary получить отфильтрованный список лидов в csv.
         * @param {number} [client] id клиента
         * @param {number} [managerId] id менеджера
         * @param {string} [status] статус лида
         * @param {number} [cityId] id города
         * @param {string} [packet] пакет лида
         * @param {string} [transferDateFrom] дата передачи лида от
         * @param {string} [transferDateTo] дата передачи лида по
         * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadCsvGet: async (client?: number, managerId?: number, status?: string, cityId?: number, packet?: string, transferDateFrom?: string, transferDateTo?: string, sorting?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/lead/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }

            if (managerId !== undefined) {
                localVarQueryParameter['managerId'] = managerId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['cityId'] = cityId;
            }

            if (packet !== undefined) {
                localVarQueryParameter['packet'] = packet;
            }

            if (transferDateFrom !== undefined) {
                localVarQueryParameter['transferDateFrom'] = transferDateFrom;
            }

            if (transferDateTo !== undefined) {
                localVarQueryParameter['transferDateTo'] = transferDateTo;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * решение модератора.
         * @summary решение модератора.
         * @param {string} id Идентификатор лида
         * @param {LeadModeratorDecision} data Данные о решение модератора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadDecisionIdPut: async (id: string, data: LeadModeratorDecision, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadDecisionIdPut', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiLeadDecisionIdPut', 'data', data)
            const localVarPath = `/api/lead/decision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * замена на 0.
         * @summary замена на 0.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadExchangeOnZeroIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadExchangeOnZeroIdPut', 'id', id)
            const localVarPath = `/api/lead/exchange-on-zero/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * решение модератора.
         * @summary решение модератора.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadFinalizeIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadFinalizeIdPut', 'id', id)
            const localVarPath = `/api/lead/finalize/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить отфильтрованный список лидов.
         * @summary получить отфильтрованный список лидов.
         * @param {number} [client] id клиента
         * @param {number} [managerId] id менеджера
         * @param {string} [status] статус лида
         * @param {number} [cityId] id города
         * @param {string} [packet] пакет лида
         * @param {string} [transferDateFrom] дата передачи лида от
         * @param {string} [transferDateTo] дата передачи лида по
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadGet: async (client?: number, managerId?: number, status?: string, cityId?: number, packet?: string, transferDateFrom?: string, transferDateTo?: string, offset?: number, limit?: number, sorting?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/lead/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }

            if (managerId !== undefined) {
                localVarQueryParameter['managerId'] = managerId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (cityId !== undefined) {
                localVarQueryParameter['cityId'] = cityId;
            }

            if (packet !== undefined) {
                localVarQueryParameter['packet'] = packet;
            }

            if (transferDateFrom !== undefined) {
                localVarQueryParameter['transferDateFrom'] = transferDateFrom;
            }

            if (transferDateTo !== undefined) {
                localVarQueryParameter['transferDateTo'] = transferDateTo;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить лид по id.
         * @summary получить лид по id.
         * @param {string} id Id лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadIdGet', 'id', id)
            const localVarPath = `/api/lead/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * установить модератора для лида.
         * @summary установить модератора для лида.
         * @param {string} id Идентификатор лида
         * @param {LeadSetModeratorRequest} data Данные о решение модератора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadModeratorIdPut: async (id: string, data: LeadSetModeratorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadModeratorIdPut', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('apiLeadModeratorIdPut', 'data', data)
            const localVarPath = `/api/lead/moderator/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отклонить лид.
         * @summary отклонить лид.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadRejectIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLeadRejectIdPut', 'id', id)
            const localVarPath = `/api/lead/reject/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список статусов лида.
         * @summary получить список статусов лида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadStatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/lead/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadsApi - functional programming interface
 * @export
 */
export const LeadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadsApiAxiosParamCreator(configuration)
    return {
        /**
         * анулировать лид.
         * @summary анулировать лид.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadAnnulIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadAnnulIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * редактирование причины клиента.
         * @summary редактирование причины клиента.
         * @param {string} id Идентификатор лида
         * @param {LeadClientCauseEdit} data Данные о причине клиента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadClientCauseIdPut(id: string, data: LeadClientCauseEdit, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadClientCauseIdPut(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить отфильтрованный список лидов  в csv.
         * @summary получить отфильтрованный список лидов в csv.
         * @param {number} [client] id клиента
         * @param {number} [managerId] id менеджера
         * @param {string} [status] статус лида
         * @param {number} [cityId] id города
         * @param {string} [packet] пакет лида
         * @param {string} [transferDateFrom] дата передачи лида от
         * @param {string} [transferDateTo] дата передачи лида по
         * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadCsvGet(client?: number, managerId?: number, status?: string, cityId?: number, packet?: string, transferDateFrom?: string, transferDateTo?: string, sorting?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadCsvGet(client, managerId, status, cityId, packet, transferDateFrom, transferDateTo, sorting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * решение модератора.
         * @summary решение модератора.
         * @param {string} id Идентификатор лида
         * @param {LeadModeratorDecision} data Данные о решение модератора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadDecisionIdPut(id: string, data: LeadModeratorDecision, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadDecisionIdPut(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * замена на 0.
         * @summary замена на 0.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadExchangeOnZeroIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadExchangeOnZeroIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * решение модератора.
         * @summary решение модератора.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadFinalizeIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadFinalizeIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить отфильтрованный список лидов.
         * @summary получить отфильтрованный список лидов.
         * @param {number} [client] id клиента
         * @param {number} [managerId] id менеджера
         * @param {string} [status] статус лида
         * @param {number} [cityId] id города
         * @param {string} [packet] пакет лида
         * @param {string} [transferDateFrom] дата передачи лида от
         * @param {string} [transferDateTo] дата передачи лида по
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadGet(client?: number, managerId?: number, status?: string, cityId?: number, packet?: string, transferDateFrom?: string, transferDateTo?: string, offset?: number, limit?: number, sorting?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadLeadListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadGet(client, managerId, status, cityId, packet, transferDateFrom, transferDateTo, offset, limit, sorting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить лид по id.
         * @summary получить лид по id.
         * @param {string} id Id лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * установить модератора для лида.
         * @summary установить модератора для лида.
         * @param {string} id Идентификатор лида
         * @param {LeadSetModeratorRequest} data Данные о решение модератора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadModeratorIdPut(id: string, data: LeadSetModeratorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadModeratorIdPut(id, data, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отклонить лид.
         * @summary отклонить лид.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadRejectIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadRejectIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список статусов лида.
         * @summary получить список статусов лида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLeadStatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadItemDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLeadStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadsApi - factory interface
 * @export
 */
export const LeadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadsApiFp(configuration)
    return {
        /**
         * анулировать лид.
         * @summary анулировать лид.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadAnnulIdPut(id: string, options?: any): AxiosPromise<LeadLeadResponse> {
            return localVarFp.apiLeadAnnulIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * редактирование причины клиента.
         * @summary редактирование причины клиента.
         * @param {string} id Идентификатор лида
         * @param {LeadClientCauseEdit} data Данные о причине клиента
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadClientCauseIdPut(id: string, data: LeadClientCauseEdit, options?: any): AxiosPromise<LeadLeadResponse> {
            return localVarFp.apiLeadClientCauseIdPut(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * получить отфильтрованный список лидов  в csv.
         * @summary получить отфильтрованный список лидов в csv.
         * @param {number} [client] id клиента
         * @param {number} [managerId] id менеджера
         * @param {string} [status] статус лида
         * @param {number} [cityId] id города
         * @param {string} [packet] пакет лида
         * @param {string} [transferDateFrom] дата передачи лида от
         * @param {string} [transferDateTo] дата передачи лида по
         * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadCsvGet(client?: number, managerId?: number, status?: string, cityId?: number, packet?: string, transferDateFrom?: string, transferDateTo?: string, sorting?: string, options?: any): AxiosPromise<LeadFileResponse> {
            return localVarFp.apiLeadCsvGet(client, managerId, status, cityId, packet, transferDateFrom, transferDateTo, sorting, options).then((request) => request(axios, basePath));
        },
        /**
         * решение модератора.
         * @summary решение модератора.
         * @param {string} id Идентификатор лида
         * @param {LeadModeratorDecision} data Данные о решение модератора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadDecisionIdPut(id: string, data: LeadModeratorDecision, options?: any): AxiosPromise<LeadLeadResponse> {
            return localVarFp.apiLeadDecisionIdPut(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * замена на 0.
         * @summary замена на 0.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadExchangeOnZeroIdPut(id: string, options?: any): AxiosPromise<LeadLeadResponse> {
            return localVarFp.apiLeadExchangeOnZeroIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * решение модератора.
         * @summary решение модератора.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadFinalizeIdPut(id: string, options?: any): AxiosPromise<LeadLeadResponse> {
            return localVarFp.apiLeadFinalizeIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить отфильтрованный список лидов.
         * @summary получить отфильтрованный список лидов.
         * @param {number} [client] id клиента
         * @param {number} [managerId] id менеджера
         * @param {string} [status] статус лида
         * @param {number} [cityId] id города
         * @param {string} [packet] пакет лида
         * @param {string} [transferDateFrom] дата передачи лида от
         * @param {string} [transferDateTo] дата передачи лида по
         * @param {number} [offset] Начальный индекс
         * @param {number} [limit] Лимит
         * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadGet(client?: number, managerId?: number, status?: string, cityId?: number, packet?: string, transferDateFrom?: string, transferDateTo?: string, offset?: number, limit?: number, sorting?: string, options?: any): AxiosPromise<LeadLeadListResponse> {
            return localVarFp.apiLeadGet(client, managerId, status, cityId, packet, transferDateFrom, transferDateTo, offset, limit, sorting, options).then((request) => request(axios, basePath));
        },
        /**
         * получить лид по id.
         * @summary получить лид по id.
         * @param {string} id Id лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadIdGet(id: string, options?: any): AxiosPromise<LeadLeadResponse> {
            return localVarFp.apiLeadIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * установить модератора для лида.
         * @summary установить модератора для лида.
         * @param {string} id Идентификатор лида
         * @param {LeadSetModeratorRequest} data Данные о решение модератора
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadModeratorIdPut(id: string, data: LeadSetModeratorRequest, options?: any): AxiosPromise<LeadLeadResponse> {
            return localVarFp.apiLeadModeratorIdPut(id, data, options).then((request) => request(axios, basePath));
        },
        /**
         * отклонить лид.
         * @summary отклонить лид.
         * @param {string} id Идентификатор лида
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadRejectIdPut(id: string, options?: any): AxiosPromise<LeadLeadResponse> {
            return localVarFp.apiLeadRejectIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить список статусов лида.
         * @summary получить список статусов лида.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLeadStatusGet(options?: any): AxiosPromise<Array<LeadItemDescription>> {
            return localVarFp.apiLeadStatusGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadsApi - object-oriented interface
 * @export
 * @class LeadsApi
 * @extends {BaseAPI}
 */
export class LeadsApi extends BaseAPI {
    /**
     * анулировать лид.
     * @summary анулировать лид.
     * @param {string} id Идентификатор лида
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadAnnulIdPut(id: string, options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadAnnulIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * редактирование причины клиента.
     * @summary редактирование причины клиента.
     * @param {string} id Идентификатор лида
     * @param {LeadClientCauseEdit} data Данные о причине клиента
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadClientCauseIdPut(id: string, data: LeadClientCauseEdit, options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadClientCauseIdPut(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить отфильтрованный список лидов  в csv.
     * @summary получить отфильтрованный список лидов в csv.
     * @param {number} [client] id клиента
     * @param {number} [managerId] id менеджера
     * @param {string} [status] статус лида
     * @param {number} [cityId] id города
     * @param {string} [packet] пакет лида
     * @param {string} [transferDateFrom] дата передачи лида от
     * @param {string} [transferDateTo] дата передачи лида по
     * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadCsvGet(client?: number, managerId?: number, status?: string, cityId?: number, packet?: string, transferDateFrom?: string, transferDateTo?: string, sorting?: string, options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadCsvGet(client, managerId, status, cityId, packet, transferDateFrom, transferDateTo, sorting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * решение модератора.
     * @summary решение модератора.
     * @param {string} id Идентификатор лида
     * @param {LeadModeratorDecision} data Данные о решение модератора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadDecisionIdPut(id: string, data: LeadModeratorDecision, options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadDecisionIdPut(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * замена на 0.
     * @summary замена на 0.
     * @param {string} id Идентификатор лида
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadExchangeOnZeroIdPut(id: string, options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadExchangeOnZeroIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * решение модератора.
     * @summary решение модератора.
     * @param {string} id Идентификатор лида
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadFinalizeIdPut(id: string, options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadFinalizeIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить отфильтрованный список лидов.
     * @summary получить отфильтрованный список лидов.
     * @param {number} [client] id клиента
     * @param {number} [managerId] id менеджера
     * @param {string} [status] статус лида
     * @param {number} [cityId] id города
     * @param {string} [packet] пакет лида
     * @param {string} [transferDateFrom] дата передачи лида от
     * @param {string} [transferDateTo] дата передачи лида по
     * @param {number} [offset] Начальный индекс
     * @param {number} [limit] Лимит
     * @param {string} [sorting] параметры для сортировки, пример (created_at:+;modified_at:-)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadGet(client?: number, managerId?: number, status?: string, cityId?: number, packet?: string, transferDateFrom?: string, transferDateTo?: string, offset?: number, limit?: number, sorting?: string, options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadGet(client, managerId, status, cityId, packet, transferDateFrom, transferDateTo, offset, limit, sorting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить лид по id.
     * @summary получить лид по id.
     * @param {string} id Id лида
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadIdGet(id: string, options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * установить модератора для лида.
     * @summary установить модератора для лида.
     * @param {string} id Идентификатор лида
     * @param {LeadSetModeratorRequest} data Данные о решение модератора
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadModeratorIdPut(id: string, data: LeadSetModeratorRequest, options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadModeratorIdPut(id, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отклонить лид.
     * @summary отклонить лид.
     * @param {string} id Идентификатор лида
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadRejectIdPut(id: string, options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadRejectIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список статусов лида.
     * @summary получить список статусов лида.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadsApi
     */
    public apiLeadStatusGet(options?: AxiosRequestConfig) {
        return LeadsApiFp(this.configuration).apiLeadStatusGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManagersApi - axios parameter creator
 * @export
 */
export const ManagersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех менеджеров.
         * @summary получить список всех менеджеров.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/manager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить список всех модераторов.
         * @summary получить список всех модераторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModeratorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moderator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagersApi - functional programming interface
 * @export
 */
export const ManagersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagersApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех менеджеров.
         * @summary получить список всех менеджеров.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiManagerGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiManagerGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить список всех модераторов.
         * @summary получить список всех модераторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiModeratorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiModeratorGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManagersApi - factory interface
 * @export
 */
export const ManagersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagersApiFp(configuration)
    return {
        /**
         * получить список всех менеджеров.
         * @summary получить список всех менеджеров.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiManagerGet(options?: any): AxiosPromise<Array<UserUserResponse>> {
            return localVarFp.apiManagerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * получить список всех модераторов.
         * @summary получить список всех модераторов.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiModeratorGet(options?: any): AxiosPromise<Array<UserUserResponse>> {
            return localVarFp.apiModeratorGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagersApi - object-oriented interface
 * @export
 * @class ManagersApi
 * @extends {BaseAPI}
 */
export class ManagersApi extends BaseAPI {
    /**
     * получить список всех менеджеров.
     * @summary получить список всех менеджеров.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public apiManagerGet(options?: AxiosRequestConfig) {
        return ManagersApiFp(this.configuration).apiManagerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить список всех модераторов.
     * @summary получить список всех модераторов.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagersApi
     */
    public apiModeratorGet(options?: AxiosRequestConfig) {
        return ManagersApiFp(this.configuration).apiModeratorGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PacketApi - axios parameter creator
 * @export
 */
export const PacketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Возвращает список  городов портала.
         * @summary Возвращает список  городов портала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPacketGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/packet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PacketApi - functional programming interface
 * @export
 */
export const PacketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PacketApiAxiosParamCreator(configuration)
    return {
        /**
         * Возвращает список  городов портала.
         * @summary Возвращает список  городов портала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPacketGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PacketPacketListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPacketGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PacketApi - factory interface
 * @export
 */
export const PacketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PacketApiFp(configuration)
    return {
        /**
         * Возвращает список  городов портала.
         * @summary Возвращает список  городов портала.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPacketGet(options?: any): AxiosPromise<PacketPacketListResponse> {
            return localVarFp.apiPacketGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PacketApi - object-oriented interface
 * @export
 * @class PacketApi
 * @extends {BaseAPI}
 */
export class PacketApi extends BaseAPI {
    /**
     * Возвращает список  городов портала.
     * @summary Возвращает список  городов портала.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PacketApi
     */
    public apiPacketGet(options?: AxiosRequestConfig) {
        return PacketApiFp(this.configuration).apiPacketGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdDelete', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdGet', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * смена пароля пользователя.
         * @summary смена пароля пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserChangePasswordRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPasswordPut: async (id: string, user: UserChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdPasswordPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserIdPasswordPut', 'user', user)
            const localVarPath = `/api/user/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut: async (id: string, user: UserUpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdPut', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserIdPut', 'user', user)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (user: UserCreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiUserPost', 'user', user)
            const localVarPath = `/api/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * смена пароля пользователя.
         * @summary смена пароля пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserChangePasswordRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdPasswordPut(id: string, user: UserChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdPasswordPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdPut(id, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * получить список всех источников.
         * @summary получить список всех пользователей.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(options?: any): AxiosPromise<Array<UserUserResponse>> {
            return localVarFp.apiUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * удалить пользователя
         * @summary удалить пользователя
         * @param {string} id Идентификатор пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * получить платеж по id.
         * @summary получить платеж по id.
         * @param {string} id Идентификатор платежа
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet(id: string, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * смена пароля пользователя.
         * @summary смена пароля пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserChangePasswordRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPasswordPut(id: string, user: UserChangePasswordRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdPasswordPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * обновление пользователя.
         * @summary обновление пользователя.
         * @param {string} id Идентификатор пользователя
         * @param {UserUpdateUserRequest} user Данные для обновления пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserIdPut(id, user, options).then((request) => request(axios, basePath));
        },
        /**
         * создание пользователя.
         * @summary создание пользователя.
         * @param {UserCreateUserRequest} user Данные пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(user: UserCreateUserRequest, options?: any): AxiosPromise<UserUserResponse> {
            return localVarFp.apiUserPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * получить список всех источников.
     * @summary получить список всех пользователей.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * удалить пользователя
     * @summary удалить пользователя
     * @param {string} id Идентификатор пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdDelete(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * получить платеж по id.
     * @summary получить платеж по id.
     * @param {string} id Идентификатор платежа
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdGet(id: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * смена пароля пользователя.
     * @summary смена пароля пользователя.
     * @param {string} id Идентификатор пользователя
     * @param {UserChangePasswordRequest} user Данные для обновления пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdPasswordPut(id: string, user: UserChangePasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdPasswordPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * обновление пользователя.
     * @summary обновление пользователя.
     * @param {string} id Идентификатор пользователя
     * @param {UserUpdateUserRequest} user Данные для обновления пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserIdPut(id: string, user: UserUpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserIdPut(id, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * создание пользователя.
     * @summary создание пользователя.
     * @param {UserCreateUserRequest} user Данные пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUserPost(user: UserCreateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUserPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WidgetApi - axios parameter creator
 * @export
 */
export const WidgetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * отправить на прозвон в КЦ.
         * @summary отправить на прозвон в КЦ.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWidgetKcKeyIdPost: async (id: string, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWidgetKcKeyIdPost', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiWidgetKcKeyIdPost', 'key', key)
            const localVarPath = `/api/widget/kc/{key}/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * есть ли лид в системе.
         * @summary есть ли лид в системе.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWidgetLeadKeyIdPost: async (id: string, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWidgetLeadKeyIdPost', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiWidgetLeadKeyIdPost', 'key', key)
            const localVarPath = `/api/widget/lead/{key}/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * отправить на модерацию.
         * @summary отправить на модерацию.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWidgetModerationKeyIdPost: async (id: string, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWidgetModerationKeyIdPost', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiWidgetModerationKeyIdPost', 'key', key)
            const localVarPath = `/api/widget/moderation/{key}/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WidgetApi - functional programming interface
 * @export
 */
export const WidgetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WidgetApiAxiosParamCreator(configuration)
    return {
        /**
         * отправить на прозвон в КЦ.
         * @summary отправить на прозвон в КЦ.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWidgetKcKeyIdPost(id: string, key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWidgetKcKeyIdPost(id, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * есть ли лид в системе.
         * @summary есть ли лид в системе.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWidgetLeadKeyIdPost(id: string, key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWidgetLeadKeyIdPost(id, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * отправить на модерацию.
         * @summary отправить на модерацию.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWidgetModerationKeyIdPost(id: string, key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWidgetModerationKeyIdPost(id, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WidgetApi - factory interface
 * @export
 */
export const WidgetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WidgetApiFp(configuration)
    return {
        /**
         * отправить на прозвон в КЦ.
         * @summary отправить на прозвон в КЦ.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWidgetKcKeyIdPost(id: string, key: string, options?: any): AxiosPromise<DeliveryErrorResponse> {
            return localVarFp.apiWidgetKcKeyIdPost(id, key, options).then((request) => request(axios, basePath));
        },
        /**
         * есть ли лид в системе.
         * @summary есть ли лид в системе.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWidgetLeadKeyIdPost(id: string, key: string, options?: any): AxiosPromise<DeliveryErrorResponse> {
            return localVarFp.apiWidgetLeadKeyIdPost(id, key, options).then((request) => request(axios, basePath));
        },
        /**
         * отправить на модерацию.
         * @summary отправить на модерацию.
         * @param {string} id Идентификатор лида
         * @param {string} key Api ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWidgetModerationKeyIdPost(id: string, key: string, options?: any): AxiosPromise<DeliveryErrorResponse> {
            return localVarFp.apiWidgetModerationKeyIdPost(id, key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WidgetApi - object-oriented interface
 * @export
 * @class WidgetApi
 * @extends {BaseAPI}
 */
export class WidgetApi extends BaseAPI {
    /**
     * отправить на прозвон в КЦ.
     * @summary отправить на прозвон в КЦ.
     * @param {string} id Идентификатор лида
     * @param {string} key Api ключ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public apiWidgetKcKeyIdPost(id: string, key: string, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).apiWidgetKcKeyIdPost(id, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * есть ли лид в системе.
     * @summary есть ли лид в системе.
     * @param {string} id Идентификатор лида
     * @param {string} key Api ключ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public apiWidgetLeadKeyIdPost(id: string, key: string, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).apiWidgetLeadKeyIdPost(id, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * отправить на модерацию.
     * @summary отправить на модерацию.
     * @param {string} id Идентификатор лида
     * @param {string} key Api ключ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public apiWidgetModerationKeyIdPost(id: string, key: string, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).apiWidgetModerationKeyIdPost(id, key, options).then((request) => request(this.axios, this.basePath));
    }
}


