import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { Menu } from "../../../components/menu"
import { Trash } from "tabler-icons-react"
import { Packet } from "../../../../domain/packet/packet"

export const CauseAdd = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const causeRef = useRef<HTMLInputElement>(null)
    const selectRef = useRef<HTMLSelectElement>(null)
    const [packetsList, setPacketsList] = useState<Packet[]>([])

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        packetIds: number[],
        items: string[],
    }>({
        packetIds: [],
        items: [],
    })

    const submit = () => {
       
        if (form.packetIds.length > 0 && form.items.length > 0) {
            if (id) {
                useCases?.causeUseCase.UpdateCause(parseInt(id!), form.packetIds!, form.items, () => { navigate(-1) })
            } else {
                useCases?.causeUseCase.CreateCause(form.packetIds!, form.items, () => { navigate(-1) })
            }
        }
    }

    const readCauseById = async (id: UniqueId) => {
        let response = await useCases?.causeUseCase.ReadCauseById(id)
        if (response instanceof Error) {

        } else {
            setForm({
                packetIds: response?.PacketIds()!,
                items: response?.Items()!
            })
        }
    }

    useEffect(() => {
        if (id) {
            readCauseById(id)
        }
    }, [id])

    const deleteItem = (del: number) => {
        let newCauses: string[] = []
        form.items.forEach((cause, index) => {
            if (index == del) {
                return
            }
            newCauses.push(cause)
        })

        setForm({
            ...form,
            items: newCauses,
        })
    }

    const deletePacket = (del: number) => {

        setForm({
            ...form,
            packetIds: [...form.packetIds.filter((packetId) => packetId != del)]
        })
    }

    const getPacketname = (id: number) => {
        let name = ""
        packetsList.forEach((item) => {
            if (item.Id() == id) {
                name = item.Name()
            }
        })
        return name
    }

    const getPackets = async () => {
        let response = await useCases?.leadUseCase.ReadPacketList()
        if (response instanceof Error) {

        } else {
            setPacketsList(response!)
        }
    }

    const addCauseToList = (cause: string) => {
        let newCauses = [...form.items]
        newCauses.push(cause)
        setForm({
            ...form,
            items: [...newCauses],
        })
    }

    const appendPacketToList = (id: number) => {
        let newPackets = [...form.packetIds]
        newPackets.push(id)
        setForm({
            ...form,
            packetIds: newPackets,
        })
    }

    useEffect(() => {
        getPackets()
    }, [])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Причины модерации /</span> Добавление группы причин</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Добавление группы причин</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12 mb-4 mb-xl-0">
                                        <span className="text-light">Пакеты</span>
                                        <div className="mt-3">
                                            <div className="list-group list-group-flush">
                                                {form.packetIds.length == 0 ? <span>Не добавлено ни одного пакета</span> : <></>}
                                                {form.packetIds.map((packet, index) => {
                                                    return <a href="#" onClick={(e) => { e.preventDefault() }} className="list-group-item list-group-item-action" style={{ display: "flex", justifyContent: "space-between" }}>{getPacketname(packet)} <i><Trash size={20} color="red" onClick={() => { deletePacket(packet) }} /></i></a>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3 mt-3 align-self-center" style={{ display: "flex", justifyItems: "center", alignItems: "center" }}>
                                    <div className="mb-3 col-lg-11">
                                        <label htmlFor="defaultSelect" className="form-label">Пакет</label>
                                        <select id="defaultSelect" className="form-select" ref={selectRef}>
                                            <option selected disabled>Выбериет пакет для добавляения</option>
                                            {packetsList.map((packet) => {
                                                if (form.packetIds.includes(packet.Id())) {
                                                    return
                                                }
                                                return <option value={packet.Id()}>{packet.Name()}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-lg-1">
                                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => { appendPacketToList(parseInt(selectRef.current?.value!)) }}>Добавить</button>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="row">
                                    <div className="col-lg-12 mb-4 mb-xl-0">
                                        <span className="text-light">Причины модерации</span>
                                        <div className="mt-3">
                                            <div className="list-group list-group-flush">
                                                {form.items.length == 0 ? <span>Не добавлено ни одной причины</span> : <></>}
                                                {form.items.map((cause, index) => {
                                                    return <a href="#" onClick={(e) => { e.preventDefault() }} className="list-group-item list-group-item-action" style={{ display: "flex", justifyContent: "space-between" }}>{cause} <i><Trash size={20} color="red" onClick={() => { deleteItem(index) }} /></i></a>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3 mt-3 align-self-center" style={{ display: "flex", justifyItems: "end", alignItems: "end" }}>
                                    <div className="col-lg-11">
                                        <input type="text" ref={causeRef} className="form-control" placeholder="Введите новую причину" />
                                    </div>
                                    <div className="col-lg-1">
                                        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => { addCauseToList(causeRef.current?.value!); }}>Добавить</button>
                                    </div>
                                </div>
                                <div className="row mt-5" style={{ display: "flex", justifyContent: "end" }}>
                                    <button key="submit" className={"btn btn-primary d-grid w-100"} style={{ maxWidth: 100 }} onClick={() => submit()}>Сохранить</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
