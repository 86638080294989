import axios from "axios";
import { Cause } from "../../../domain/cause/cause";
import { Configuration, CausesApi } from "../generated";
import { CauseInterface } from "../../../useCase/cause/adapters/interface";

export class CausesRepository implements CauseInterface {
    private service: CausesApi;

    constructor() {
        this.service = new CausesApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async ReadCauseById(id: UniqueId): Promise<Cause | Error> {
        try {
            let response = await this.service.apiCauseIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
               
                let cause = new Cause(response.data.id!, response.data.packet_ids!, response.data.items!)
                return cause

            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadCauseForLead(id: number): Promise<Cause | Error> {
        try {
            let response = await this.service.apiCauseLeadIdGet(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
               
                let cause = new Cause(response.data.id!, response.data.packet_ids!, response.data.items!)
                return cause

            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
    
    async ReadCauses(): Promise<Cause[] | Error> {
        try {
            let response = await this.service.apiCauseGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let causes: Cause[] = []
                response.data.forEach(causeData => {

                    let cause = new Cause(causeData.id!, causeData.packet_ids!, causeData.items!)
                    causes.push(cause)
                });
                return causes
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteCause(id: number): Promise<string | Error> {
        try {
            let response = await this.service.apiCauseIdDelete(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async CreateCause(packet_ids: number[], items: string[]): Promise<Cause | Error> {
        try {
            let response = await this.service.apiCausePost({packet_ids: packet_ids, items: items}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let cause = new Cause(response.data.id!, response.data.packet_ids!, response.data.items!)
                return cause
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateCause(id: number, packet_ids: number[], items: string[]): Promise<Cause | Error> {
        try {
            let response = await this.service.apiCauseIdPut(id.toString(), {packet_ids: packet_ids, items: items}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
    
                let cause = new Cause(response.data.id!, response.data.packet_ids!, response.data.items!)
                return cause
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }
}