import { AmoFile } from "../../domain/amofile/amofile";
import { AmoFileActionTypes } from "../action-types";
import { AmoFileActions } from "../actions";
import produce from 'immer';


interface AmoFileState {
    loading: boolean | null;
    count: number;
    amofiles: AmoFile[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: AmoFileState = {
    count: 0,
    loading: null,
    amofiles: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: AmoFileState = initialState, action: AmoFileActions) => {
    switch (action.type) {
        case AmoFileActionTypes.AMOFILE_REQUEST_SEND:
            state.loading = true;
            return state;
        case AmoFileActionTypes.AMOFILE_SUCCESS:
            state.loading = false;
            state.count = action.payload.count;
            state.amofiles = action.payload.list;
            return state;
        case AmoFileActionTypes.AMOFILE_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case AmoFileActionTypes.AMOFILE_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case AmoFileActionTypes.AMOFILE_CREATE_SUCCESS:
            state.entityLoading = false
            state.amofiles!.push(...action.payload)
            state.entityError = ""
            return state;
        case AmoFileActionTypes.AMOFILE_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case AmoFileActionTypes.AMOFILE_DELETE_SUCCESS:
            state.amofiles = state.amofiles!.filter((amofile) => amofile.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
