import { AmoFilesRepository } from "../repository/api/amofile/amofile"
import { Auth } from "../repository/api/auth/auth"
import { CausesRepository } from "../repository/api/cause/cause"
import { CitysRepository } from "../repository/api/city/city"
import { ClientsRepository } from "../repository/api/client/client"
import { LeadsRepository } from "../repository/api/lead/lead"
import { ManagersRepository } from "../repository/api/managers/managers"
import { PacketsRepository } from "../repository/api/packet/packet"
import { UsersRepository } from "../repository/api/user/user"
import { AmofileUseCases } from "../useCase/amofile/amofile"
 
import { AuthorizationUseCases } from "../useCase/authorization/authorization"
import { CauseUseCases } from "../useCase/cause/cause"
import { LeadUseCases } from "../useCase/leads/leads"
 
import { UserUseCases } from "../useCase/users/user"


export const useUseCases = (actions:any) => {
    const authUseCase = new AuthorizationUseCases(new Auth(), actions)
     
    const leadUseCase = new LeadUseCases(new LeadsRepository(), new CitysRepository(), new ManagersRepository(), new PacketsRepository(), new ClientsRepository(), actions) 
    const userUseCase = new UserUseCases(new UsersRepository(), actions)
    const causeUseCase = new CauseUseCases(new CausesRepository(), actions)
    const amofileUseCase = new AmofileUseCases(new AmoFilesRepository(), actions);

    return {authUseCase,  leadUseCase, userUseCase, causeUseCase, amofileUseCase}
}