import { Dispatch } from "react"
import { CauseActionTypes } from "../action-types";
import { CauseActions } from "../actions"
import { Cause } from "../../domain/cause/cause";

export const SendCauseListRequest = () => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_REQUEST_SEND,
          });
    }
}

export const CauseListSuccess = (list: Cause[]) => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_SUCCESS,
            payload: list,
        });
    }
}

export const CauseListError = (error:string) => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_ERROR,
            payload: error,
        });
    }
}

export const CauseCreateRequest = () => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_CREATE_REQUEST,
          });
    }
}

export const CauseCreateSuccess = (cause: Cause) => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_CREATE_SUCCESS,
            payload: cause,
          });
    }
}

export const CauseCreateError = (message: string) => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_CREATE_ERROR,
            payload: message,
          });
    }
}


export const CauseUpdateRequest = () => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_UPDATE_REQUEST,
          });
    }
}

export const CauseUpdateSuccess = (category: Cause) => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const CauseUpdateError = (message: string) => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const CauseDeleteRequest = () => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_DELETE_REQUEST,
          });
    }
}

export const CauseDeleteSuccess = (id: number) => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const CauseDeleteError = (message: string) => {
    return async (dispatch: Dispatch<CauseActions>) => {
        dispatch({
            type: CauseActionTypes.CAUSE_DELETE_ERROR,
            payload: message,
          });
    }
}