import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import { Packet } from "../../../../domain/packet/packet"
import Paginator from "../../../components/paginator/paginator"
import { ChevronDown, ChevronUp, Trash } from "tabler-icons-react"

export const AmofileListPage = () => {
    let { id } = useParams();
    const [page, setPage] = useState(0)
    const [countOnPage, setCountOnPage] = useState(50)
    const [checked, setChecked] = useState<number[]>([])

    const [form, setForm] = useState<{offset: number, limit: number}>({
        offset: 0,
        limit: 50,
    })

    const [sorting, setSorting] = useState("created_at:+")


    let useCases = useContext(UseCasesContext)

    const amofile = useTypedSelector(({ amofile }) => {
        return amofile
    })



    useEffect(()=>{
        useCases?.amofileUseCase.ReadAmofiles(form.offset, form.limit, sorting)
    },[sorting])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Загрузка записей /</span> Записи</h4>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"/amofile/add/"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>Добавить</span></Link>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <Paginator allCount={amofile?.count!} page={page} setPage={(page: number) => setPage(page)} countOnPage={countOnPage} setCountOnPage={(count: number) => setCountOnPage(count)} />
                        </div>
                        {checked.length>0 && useCases?.authUseCase.IsAdminUser()?<div className="col-sm-12 col-md-12">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <button onClick={()=>{useCases?.amofileUseCase.MassDeleteAmofiles(checked); setChecked([])}} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span><Trash size={18} style={{marginRight:"10px"}} />Удалить выделенные</span></button>
                                </div>
                            </div>
                        </div>:<></>}
                        <div className="card">
                            <div className="table-responsive text-nowrap">
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th style={{width:"1%"}}>ID</th>
                                            <th style={{width:"40%"}}>Файл</th>
                                            <th style={{width:"30%"}}>Лид</th>
                                            <th style={{cursor: "pointer", display:"flex", alignItems: "center", width:"100%"}} onClick={()=>{sorting==="created_at:+"?setSorting("created_at:-"):setSorting("created_at:+")}}>Дата загрузки <div style={{display: "flex", flexDirection:"column", marginLeft:5}}><ChevronUp size={15} color={sorting=="created_at:+"?"black":"grey"} /> <ChevronDown size={15} color={sorting=="created_at:-"?"black":"grey"} /></div></th>
                                            <th style={{width:"5%"}}>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            amofile?.amofiles && amofile.amofiles.map((item, index)=>{
                                                return <TableRow checked={checked.indexOf(item.Id()) !== -1} setChecked={()=>{
                                                    if (checked.indexOf(item.Id()) !== -1) {
                                                        setChecked([...checked.filter((i)=> i != item.Id())])
                                                    } else {
                                                        setChecked([...checked, item.Id()])
                                                    }
                                                }} amofile={item} last={amofile?.amofiles!.length - 1 == index?true:false}/>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}