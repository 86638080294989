import { useContext, useEffect, useState } from "react";
import { Cause } from "../../../../domain/cause/cause";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { Packet } from "../../../../domain/packet/packet";

export const TableRow = (props: { cause: Cause, packets: Packet[], last: boolean }) => {
    const {id} = useParams()
    const [showMenu, setShowMenu] = useState(false)
    const [packet, setPacketsList] = useState<string[]>([]) 
    let useCases = useContext(UseCasesContext)

    const getPacketNames = () => {
        let packet: string[] = []

        props.packets.forEach((item)=>{
            if (props.cause.PacketIds().includes(item.Id())) {
                packet.push(item.Name())
            }
        })
        setPacketsList([...packet])
    }

    useEffect(()=>{
        getPacketNames()
    },[props.packets])

    return (<tr>
        <td style={{overflow: "hidden", textOverflow: "ellipsis"}}>{packet.join(", ")}</td>
        <td style={{overflow: "hidden", textOverflow: "ellipsis"}}>{props.cause.Items().join(', ')}</td>
        <td>
            <div className="dropdown">
                <button type="button" onClick={()=>{setShowMenu(!showMenu)}} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <DotsVertical />
                </button>
                <div className={showMenu?"dropdown-menu show":"dropdown-menu "} style={showMenu ? props.last ? { position: "absolute", inset: "auto auto 0px -20%", margin: "0px", transform: "translate3d(0px, -20.5px, 0px)" }: {position: "absolute", marginLeft: "-40%",}: {display:"none"}}>
                    <Link className="dropdown-item d-flex" to={"/cause/edit/"+props.cause.Id()}><Edit className="me-1" size={20} /> Редактировать</Link>
                    <a className="dropdown-item d-flex" href="#" onClick={(e)=>{e.preventDefault(); useCases?.causeUseCase.DeleteCause(props.cause.Id()); setShowMenu(false)}}><Trash className="me-1" size={20} /> Удалить</a>
                </div>
            </div>
        </td>
    </tr>)
}
