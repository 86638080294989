import { createContext } from 'react'
import { AuthorizationUseCases } from '../useCase/authorization/authorization';
import { LeadUseCases } from '../useCase/leads/leads';
import { UserUseCases } from '../useCase/users/user';
import { CauseUseCases } from '../useCase/cause/cause';
import { AmofileUseCases } from '../useCase/amofile/amofile';
 

interface UseCasesContextInterface {
    authUseCase: AuthorizationUseCases;
    causeUseCase: CauseUseCases; 
    leadUseCase: LeadUseCases; 
    userUseCase: UserUseCases;
    amofileUseCase: AmofileUseCases;
}

export const UseCasesContext = createContext<UseCasesContextInterface | null>(null)