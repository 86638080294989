import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { FiledType, Form } from "../../../components/form"
import { Menu } from "../../../components/menu"
import { LeadItem } from "./lead-item"
import { BrandTelegram, Plus } from "tabler-icons-react"

export const AmofileCar = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string>("")

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        leadUrl: string,
        file: File[],
    }>({
        leadUrl: "",
        file: [],
    })

    const [leads, setLeadsList] = useState<{
        phone: string,
        carId: number,
        audioFIle: File | null,
        tag: string,
        utmSource: string,
        cityName: string,
        regionName: string,
        clientName: string,
        isNew: boolean,
        sended: boolean,
        loading: boolean,
        error: boolean
    }[]>([{
        phone: "",
        carId: 0,
        audioFIle: null,
        tag: "",
        utmSource: "",
        cityName: "",
        regionName: "",
        clientName: "",
        sended: false,
        isNew: false,
        loading: false,
        error: false
    }])

    const [carModels, setCars] = useState<{ id: number, value: string }[]>([])

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (form.file !== null && form.leadUrl !== "") {
            let id = getIdFromUrl(form.leadUrl)
            if (!id) {
                setError("не удается получить id из ссылки на лид, пожалуйста проверьте ссылку")
                return
            } else {
                setError("")
            }
            useCases?.amofileUseCase.CreateAmofile(id, form.file!, () => { navigate(-1) })
        }
    }

    const getIdFromUrl: (url: string) => number = (url: string) => {
        const parsedUrl = new URL(url)
        let items = parsedUrl.pathname.split('/')
        let id = parseInt(items[items.length - 1])
        return id
    }


    const addLead = () => {
        setLeadsList([...leads, {
            phone: "",
            carId: 0,
            audioFIle: null,
            tag: "",
            utmSource: "",
            cityName: "",
            regionName: "",
            clientName: "",
            sended: false,
            isNew: false,
            loading: false,
            error: false
        }])
    }

    const updateForm = (index: number, name: string, value: any) => {
        let newFields: {
            phone: string,
            carId: number,
            audioFIle: File | null,
            tag: string,
            utmSource: string,
            cityName: string,
            regionName: string,
            clientName: string,
            sended: boolean,
            isNew: boolean,
            loading: boolean,
            error: boolean
        }[] = []
        leads.forEach((lead, i) => {
            if (i == index) {
                lead = {
                    ...lead,
                    [name]: value
                }
            }
            newFields.push(lead)
        })

        setLeadsList([...newFields])
    }

    const setLeadSended = (index: number) => {
        let newFields: {
            phone: string,
            carId: number,
            audioFIle: File | null,
            tag: string,
            utmSource: string,
            cityName: string,
            regionName: string,
            clientName: string,
            sended: boolean,
            isNew: boolean,
            loading: boolean,
            error: boolean
        }[] = []
        leads.forEach((lead, i) => {
            if (i == index) {
                lead = {
                    ...lead,
                    sended: true,
                    loading: false,
                }
            }
            newFields.push(lead)
        })

        setLeadsList([...newFields])
    }

    const setLeadError = (index: number) => {
        let newFields: {
            phone: string,
            carId: number,
            audioFIle: File | null,
            tag: string,
            utmSource: string,
            cityName: string,
            regionName: string,
            clientName: string,
            sended: boolean,
            isNew: boolean,
            loading: boolean,
            error: boolean
        }[] = []
        leads.forEach((lead, i) => {
            if (i == index) {
                lead = {
                    ...lead,
                    sended: false,
                    loading: false,
                    error: true
                }
            }
            newFields.push(lead)
        })

        setLeadsList([...newFields])
    }


    const readCarModels = async () => {
        let cars = await useCases?.amofileUseCase.ReadCarModels()
        if (cars instanceof Error) {

        } else {
            if (typeof cars === 'string') {
                let res = JSON.parse(cars)
                setCars(res.enums)
            }
        }
    }

    useEffect(() => {
        readCarModels()
    }, [])

    const sendLeads = async () => {

        for(let i = 0; i < leads.length; i++) {
            let lead = leads[i]
            if (lead.sended) {
                continue
            }
            //updateForm(i, 'loading', true)
            if (lead.audioFIle && lead.phone != "" && lead.carId != 0) {
                let resp = await useCases?.amofileUseCase.CreateAmofileCar(lead.phone, lead.cityName, lead.regionName, lead.clientName, lead.carId, lead.utmSource, lead.tag, lead.isNew, lead.audioFIle)
                if (resp) {
                    setLeadSended(i)
                } else {
                    setLeadError(i)
                }
            }
        }
    }

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4">Отправка лида в воронку Автомобили/Целевой</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Параметры</h5>
                            </div>
                            <div className="card-body">
                                {leads.map((lead, i) => {
                                    return <LeadItem index={i} values={{ phone: lead.phone, clientName: lead.clientName, cityName: lead.cityName, regionName: lead.regionName, carId: lead.carId, audioFile: lead.audioFIle, tag: lead.tag, utmSource: lead.utmSource, isNew: lead.isNew }} updateFn={updateForm} carModels={carModels} loading={lead.loading} sended={lead.sended} error={lead.error} />
                                })}

                                <div className="d-flex justify-content-end mt-5"><button className="btn btn-secondary d-grid" onClick={() => { addLead() }}><span><Plus />Добавить поле</span></button></div>
                                <div className="d-flex justify-content-end mt-5"><button className="btn btn-primary d-grid" onClick={() => { sendLeads() }}><span><BrandTelegram /> Отправить</span></button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
