import axios from "axios";

import { Configuration, ClientApi, } from "../generated";
import { Client } from "../../../domain/client/client";


export class ClientsRepository {
    private service: ClientApi;

    constructor() {
        this.service = new ClientApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async ReadClients(): Promise<Client[] | Error> {
        try {
            let response = await this.service.apiClientGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let clients: Client[] = []
                response.data!.list.forEach(clientData => {
                    let client = new Client(clientData.id!, clientData.name!)
                    clients.push(client)
                });
                return clients
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}