import { Cause } from "../../domain/cause/cause";
import { CauseActionTypes } from "../action-types";
import { CauseActions } from "../actions";
import produce from 'immer';


interface CauseState {
    loading: boolean | null;
    causes: Cause[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: CauseState = {
    loading: null,
    causes: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: CauseState = initialState, action: CauseActions) => {
    switch (action.type) {
        case CauseActionTypes.CAUSE_REQUEST_SEND:
            state.loading = true;
            return state;
        case CauseActionTypes.CAUSE_SUCCESS:
            state.loading = false;
            state.causes = action.payload;
            return state;
        case CauseActionTypes.CAUSE_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case CauseActionTypes.CAUSE_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case CauseActionTypes.CAUSE_CREATE_SUCCESS:
            state.entityLoading = false
            state.causes!.push(action.payload)
            state.entityError = ""
            return state;
        case CauseActionTypes.CAUSE_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case CauseActionTypes.CAUSE_DELETE_SUCCESS:
            state.causes = state.causes!.filter((causes) => causes.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
