import { Decision } from "./leadEnums";

export class Lead {
     
    private id: number; 
    private clientId: number; 
    private clientName: string; 
    private managerId: number; 
    private status: string; 
    private transferDate: Date; 
    private cityName: string; 
    private cityId: number; 
    private packet: string; 
    private price: number; 
    private clientReason: string; 
    private moderateAddDate: Date; 
    private moderatorId: number; 
    private decision: Decision; 
    private moderatedAt: Date; 
    private comment: string; 
    private cause: string; 
    private actionDate: Date; 
    private clientLeadsGrowth: boolean; 
    private processedToAmo: string; 
    private createdAt: Date; 
    private modifiedAt: Date;

    constructor( id: number, clientId: number, clientName: string, managerId: number, status: string, transferDate: Date, cityName: string, cityId: number, packet: string, price: number, clientReason: string, moderateAddDate: Date, moderatorId: number, decision: Decision, moderatedAt: Date, comment: string, cause: string, actionDate: Date, clientLeadsGrowth: boolean, processedToAmo: string, createdAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.clientId = clientId;
        this.clientName = clientName;
        this.managerId = managerId;
        this.status = status;
        this.transferDate = transferDate;
        this.cityName = cityName;
        this.cityId = cityId;
        this.packet = packet;
        this.price = price;
        this.clientReason = clientReason;
        this.moderateAddDate = moderateAddDate;
        this.moderatorId = moderatorId;
        this.decision = decision;
        this.moderatedAt = moderatedAt;
        this.comment = comment;
        this.cause = cause;
        this.actionDate = actionDate;
        this.clientLeadsGrowth = clientLeadsGrowth;
        this.processedToAmo = processedToAmo;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): number => {
        return this.id
    }
    public ClientId = (): number => {
        return this.clientId
    }
    public ClientName = (): string => {
        return this.clientName
    }
    public ManagerId = (): number => {
        return this.managerId
    }
    public Status = (): string => {
        return this.status
    }
    public TransferDate = (): Date => {
        return this.transferDate
    }
    public CityName = (): string => {
        return this.cityName
    }
    public CityId = (): number => {
        return this.cityId
    }
    public Packet = (): string => {
        return this.packet
    }
    public Price = (): number => {
        return this.price
    }
    public ClientReason = (): string => {
        return this.clientReason
    }
    public ModerateAddDate = (): Date => {
        return this.moderateAddDate
    }
    public ModeratorId = (): number => {
        return this.moderatorId
    }
    public Decision = (): Decision => {
        return this.decision
    }
    public ModeratedAt = (): Date => {
        return this.moderatedAt
    }
    public Comment = (): string => {
        return this.comment
    }
    public Cause = (): string => {
        return this.cause
    }
    public ActionDate = (): Date => {
        return this.actionDate
    }
    public ClientLeadsGrowth = (): boolean => {
        return this.clientLeadsGrowth
    }
    public ProcessedToAmo = (): string => {
        return this.processedToAmo
    }
    public CreatedAt = (): Date => {
        return this.createdAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}