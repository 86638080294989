import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../context/useCases"
import { Menu } from "../../../components/menu"
import { FiledType, Form } from "../../../components/form"
import { Decision, DecisionDescription } from "../../../../domain/lead/leadEnums"

export const SetModerator = () => {
    const navigate = useNavigate();
    const [moderators, setModerators] = useState<{title: string, value: number}[]>([])
    let { id } = useParams();

    let useCases = useContext(UseCasesContext)
    const [form, setForm] = useState<{
        moderatorId: number,
    }>({
        moderatorId: 0,
    })

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (id && form.moderatorId !== 0) {
            useCases?.leadUseCase.SetModeratorForLead(parseInt(id!), form.moderatorId!, () => { navigate(-1) })
        }
    }

    const readLeadById = async (id: UniqueId) => {
        let response = await useCases?.leadUseCase.ReadLeadById(parseInt(id))
        if (response instanceof Error) {

        } else {
            setForm({
                moderatorId: response?.ModeratorId()!,
            })
        }
    }

    const getDecisionOptions = async () => {
        let response = await useCases?.leadUseCase.ReadModeratorsList()
        if (response instanceof Error) {
        } else {
            let moderatorsList: {title: string, value: number}[] =[]
            response!.forEach((item)=>{
                moderatorsList.push({title: item.Name()+ " "+item.Surname(), value: item.Id()})
            })
            setModerators([...moderatorsList])
        }
    }

    useEffect(() => {
        if (id) {
            readLeadById(id)
            getDecisionOptions()
        }
    }, [id])

    const updateForm = (name: string, value: any) => {
        setForm({
            ...form,
            [name]: value,
        })
    }

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Модерация /</span> Решение по модерации</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Решение по модерации</h5>
                            </div>
                            <div className="card-body">
                                <Form
                                    state={{
                                        loading: false,
                                        error: "",
                                    }}

                                    submit={submit}

                                    fields={[
                                        {
                                            name: "moderatorId",
                                            title: "Модератор",
                                            placeholder: "Выберите модератора из списка",
                                            value: form.moderatorId,
                                            type: FiledType.SelectInt,
                                            options: moderators,
                                        },
                                    ]}
                                    btnSmall={true}
                                    submitBtnTitle={"Сохранить"}
                                    updateForm={updateForm}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
