export enum AmoFileActionTypes {
    AMOFILE_REQUEST_SEND = 'amofile_request_send',
    AMOFILE_ERROR = 'amofile_error',
    AMOFILE_SUCCESS = 'amofile_success',
    
    AMOFILE_CREATE_REQUEST = 'amofile_create_request',
    AMOFILE_CREATE_SUCCESS = 'amofile_create_success',
    AMOFILE_CREATE_ERROR = 'amofile_create_error',

    AMOFILE_DELETE_REQUEST = 'amofile_delete_request',
    AMOFILE_DELETE_SUCCESS = 'amofile_delete_success',
    AMOFILE_DELETE_ERROR = 'amofile_delete_error',

    AMOFILE_UPDATE_REQUEST = 'amofile_update_request',
    AMOFILE_UPDATE_SUCCESS = 'amofile_update_success',
    AMOFILE_UPDATE_ERROR = 'amofile_update_error',
}