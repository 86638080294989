import { Check, X } from "tabler-icons-react"

export const LeadItem = (props: {values: {phone: string, cityName: string, regionName: string, clientName: string, carId: number, audioFile: File | null, tag: string, utmSource: string, isNew: boolean}, index: number, updateFn: (index:number, name:string, value: any) => void, carModels: {id: number, value: string}[], loading: boolean, sended: boolean, error: boolean}) => {
    return (
        <div className="row g-3">
            <div className="col-md-2">
                <label className="form-label" htmlFor="multicol-username">Номер телефона</label>
                <input type="text" name="phone" className="form-control" value={props.values.phone} onChange={(e)=>{props.updateFn(props.index, e.target.name, e.target.value)}} placeholder="Номер телефона" />
            </div>
            <div className="col-md-2">
                <label className="form-label" htmlFor="multicol-email">Модель авто</label>
                <select className="form-select mb-3" required={true} value={props.values.carId} name="carId" onChange={(e)=>{props.updateFn(props.index, e.target.name, e.target.value)}}>
                    <option disabled selected value="0">Выберите авто</option>
                    {props.carModels.map((option) => {
                        return <option value={option.id}>{option.value}</option>
                    })}
                </select>
            </div>
            <div className="col-md-2">
                <div className="form-password-toggle">
                    <label className="form-label" htmlFor="multicol-password">Аудио-файл</label>
                    <input type="file" name="audioFIle" className="form-control" onChange={(e)=>{if (e.currentTarget.files) props.updateFn(props.index, e.target.name, e.currentTarget.files[0])}} placeholder="Выберите файл" />
                </div>
            </div>
            <div className="col-md-2">
                <div className="form-password-toggle">
                    <label className="form-label" htmlFor="multicol-confirm-password">Тег</label>
                    <input type="text" name="tag" className="form-control" value={props.values.tag} onChange={(e)=>{props.updateFn(props.index, e.target.name, e.target.value)}} placeholder="Тег" />
                </div>
            </div>
            <div className="col-md-2">
                <div className="form-password-toggle">
                    <label className="form-label" htmlFor="multicol-password">Utm Source</label>
                    <input type="text" name="utmSource" className="form-control" value={props.values.utmSource} onChange={(e)=>{props.updateFn(props.index, e.target.name, e.target.value)}} placeholder="Utm Source" />
                </div>
            </div>
            <div className="col-md-2">
                <div className="form-password-toggle">
                    <label className="form-label" htmlFor="multicol-password">Город</label>
                    <input type="text" name="cityName" className="form-control" value={props.values.cityName} onChange={(e)=>{props.updateFn(props.index, e.target.name, e.target.value)}} placeholder="Город" />
                </div>
            </div>
            <div className="col-md-2">
                <div className="form-password-toggle">
                    <label className="form-label" htmlFor="multicol-password">Регион</label>
                    <input type="text" name="regionName" className="form-control" value={props.values.regionName} onChange={(e)=>{props.updateFn(props.index, e.target.name, e.target.value)}} placeholder="Регион" />
                </div>
            </div>
            <div className="col-md-2">
                <div className="form-password-toggle">
                    <label className="form-label" htmlFor="multicol-password">Имя Клиента</label>
                    <input type="text" name="clientName" className="form-control" value={props.values.clientName} onChange={(e)=>{props.updateFn(props.index, e.target.name, e.target.value)}} placeholder="Имя Клиента" />
                </div>
            </div>
            <div className="col-md-2">
                <label className="form-label" htmlFor="multicol-email">Новизна авто</label>
                <select className="form-select mb-3" required={true} value={props.values.isNew.toString()} name="isNew" onChange={(e)=>{props.updateFn(props.index, e.target.name, e.target.value == "true")}}>
                    <option selected value="false">Б/У</option>
                    <option selected value="true">Новое</option>
                </select>
            </div>
            <div className="col-md-2" style={{display: "flex", alignItems: "end", marginBottom:"20px", justifyContent: "center"}}>
                {props.loading?<img src="/img/loading.gif" style={{maxWidth: "30px"}}/> : <></>}
                {props.sended?<Check size={30} color="green" />:<></>}
                {props.error?<X size={30} color="red" />:<></>}
            </div>
            
            <hr className="my-4 mx-n4"></hr>
        </div>
    )
}