export enum CauseActionTypes {
    CAUSE_REQUEST_SEND = 'cause_request_send',
    CAUSE_ERROR = 'cause_error',
    CAUSE_SUCCESS = 'cause_success',
    
    CAUSE_CREATE_REQUEST = 'cause_create_request',
    CAUSE_CREATE_SUCCESS = 'cause_create_success',
    CAUSE_CREATE_ERROR = 'cause_create_error',

    CAUSE_DELETE_REQUEST = 'cause_delete_request',
    CAUSE_DELETE_SUCCESS = 'cause_delete_success',
    CAUSE_DELETE_ERROR = 'cause_delete_error',

    CAUSE_UPDATE_REQUEST = 'cause_update_request',
    CAUSE_UPDATE_SUCCESS = 'cause_update_success',
    CAUSE_UPDATE_ERROR = 'cause_update_error',
}