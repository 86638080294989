export enum LeadActionTypes {
    LEAD_REQUEST_SEND = 'lead_request_send',
    LEAD_ERROR = 'lead_error',
    LEAD_SUCCESS = 'lead_success',
    
    LEAD_CREATE_REQUEST = 'lead_create_request',
    LEAD_CREATE_SUCCESS = 'lead_create_success',
    LEAD_CREATE_ERROR = 'lead_create_error',

    LEAD_DELETE_REQUEST = 'lead_delete_request',
    LEAD_DELETE_SUCCESS = 'lead_delete_success',
    LEAD_DELETE_ERROR = 'lead_delete_error',

    LEAD_UPDATE_REQUEST = 'lead_update_request',
    LEAD_UPDATE_SUCCESS = 'lead_update_success',
    LEAD_UPDATE_ERROR = 'lead_update_error',
}