export class AmoFile {
     
    private id: number; 
    private leadId: number; 
    private file: string; 
    private path: string; 
    private createAt: Date; 
    private modifiedAt: Date;

    constructor( id: number, leadId: number, file: string, path: string, createAt: Date, modifiedAt: Date,) {
        this.id = id;
        this.leadId = leadId;
        this.file = file;
        this.path = path;
        this.createAt = createAt;
        this.modifiedAt = modifiedAt;
        
    }

    
    public Id = (): number => {
        return this.id
    }
    public LeadId = (): number => {
        return this.leadId
    }
    public File = (): string => {
        return this.file
    }
    public Path = (): string => {
        return this.path
    }
    public CreateAt = (): Date => {
        return this.createAt
    }
    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }
}