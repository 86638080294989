import axios from "axios";

import { Configuration, ManagersApi, } from "../generated";
import { User } from "../../../domain/user/user";

export class ManagersRepository {
    private service: ManagersApi;

    constructor() {
        this.service = new ManagersApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }
    
    async ReadManagers(): Promise<User[] | Error> {
        try {
            let response = await this.service.apiManagerGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let users: User[] = []
                response.data.forEach(userData => {
                    let createdAt = new Date(userData.created_at!)
                    let modifiedAt = new Date(userData.modified_at!)

                    let user = new User(userData.id!, userData.name!, userData.surname!, userData.login!, userData.pass!, userData.role!, userData.enabled!, userData.deputy!, createdAt, modifiedAt)
                    users.push(user)
                });
                return users
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadModerators(): Promise<User[] | Error> {
        try {
            let response = await this.service.apiModeratorGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let users: User[] = []
                response.data.forEach(userData => {
                    let createdAt = new Date(userData.created_at!)
                    let modifiedAt = new Date(userData.modified_at!)

                    let user = new User(userData.id!, userData.name!, userData.surname!, userData.login!, userData.pass!, userData.role!, userData.enabled!, userData.deputy!, createdAt, modifiedAt)
                    users.push(user)
                });
                return users
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}

const formatDate = (day: Date) => {
    if (day.getFullYear() === 1) {
        return ""
    }
    const yyyy = day.getFullYear();
    let mm = (day.getMonth() + 1).toString(); // Months start at 0!
    let dd = day.getDate().toString();

    if (dd.length == 1) dd = '0' + dd;
    if (mm.length == 1) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy.toString();
}