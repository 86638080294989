import axios from "axios";
import { AmoFile } from "../../../domain/amofile/amofile";
import { AmofileApi, Configuration, } from "../generated";
import { AmoFilesInterface } from "../../../useCase/amofile/adapters/amofile";

export class AmoFilesRepository implements AmoFilesInterface {
    private service: AmofileApi;

    constructor() {
        this.service = new AmofileApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async CreateAmoFile(id: number, files: File[]): Promise<AmoFile[] | Error> {
        try {
            let response = await this.service.apiAmofileIdPost(id.toString(), files, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let amofiles: AmoFile[] = []
                response.data!.forEach(amofileData => {
                    let createdAt = new Date(amofileData.createdAt!)
                    let modifiedAt = new Date(amofileData.modifiedAt!)

                    let amofile = new AmoFile(amofileData.id!, amofileData.leadId!, amofileData.file!, amofileData.path!,  createdAt, modifiedAt)
                    amofiles.push(amofile)
                });return amofiles
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateAmoFileCar(phone: string, cityName: string, regionName: string, clientName: string, carId: number, utmSource: string, tag: string, isNew: boolean, file: File): Promise<AmoFile | Error> {
        try {
            let response = await this.service.apiAmofileCarPost(phone, carId, utmSource, tag, cityName, clientName, regionName, isNew, file, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)

                let amofile = new AmoFile(response.data.id!, response.data.leadId!, response.data.file!, response.data.path!,  createdAt, modifiedAt)
                return amofile
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateAmoFileXlsx(file: File):Promise<AmoFile | Error> {
        try {
            let response = await this.service.apiAmofileCarXlsxPost(file, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.createdAt!)
                let modifiedAt = new Date(response.data.modifiedAt!)

                let amofile = new AmoFile(response.data.id!, response.data.leadId!, response.data.file!, response.data.path!,  createdAt, modifiedAt)
                return amofile
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadCarModels(): Promise<string | Error> {
        try {
            let response = await this.service.apiAmofileModelsGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data.models!
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadAmoFiles(offset: number, limit: number, sorting: string): Promise<{ count: number, list: AmoFile[] } | Error> {
        try {
            let response = await this.service.apiAmofileGet(offset, limit, sorting, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let amofiles: AmoFile[] = []
                response.data.list!.forEach(amofileData => {
                    let createdAt = new Date(amofileData.createdAt!)
                    let modifiedAt = new Date(amofileData.modifiedAt!)

                    let amofile = new AmoFile(amofileData.id!, amofileData.leadId!, amofileData.file!, amofileData.path!,  createdAt, modifiedAt)
                    amofiles.push(amofile)
                });
                return { count: response.data.count!, list: amofiles }
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteAmoFile(id: number): Promise<string | Error> {
        try {
            let response = await this.service.apiAmofileIdDelete(id.toString(), { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async MassDeleteAmoFiles(ids: number[]): Promise<number[] | Error> {
        try {
            let response = await this.service.apiAmofileMassDelete({ids: ids}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data.deleted_ids!
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    
}

const formatDate = (day: Date) => {
    if (day.getFullYear() === 1) {
        return ""
    }
    const yyyy = day.getFullYear();
    let mm = (day.getMonth() + 1).toString(); // Months start at 0!
    let dd = day.getDate().toString();

    if (dd.length == 1) dd = '0' + dd;
    if (mm.length == 1) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy.toString();
}